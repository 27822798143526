import { useTranslation } from "react-i18next";

import { Typography, Link } from "@aviary";
import { useBreakpoints, useSharedGlobalConfig } from "@shared/hooks";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./SignUpFooter.styles";

interface Props {
  onSignInRedirect?: () => void;
  disabled?: boolean;
}

const FooterSignIn = ({ onSignInRedirect, disabled }: Props) => {
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();
  const { isEmerson } = useSharedGlobalConfig();

  const signInLink = isEmerson ? authRoutes.emerson.login : authRoutes.login;

  const renderSignInLink = () => {
    if (onSignInRedirect) {
      return (
        <Link css={styles.link} onClick={onSignInRedirect} disabled={disabled}>
          {t(l.common.SignIn)}
        </Link>
      );
    }

    return (
      <Link css={styles.link} to={signInLink}>
        {t(l.common.SignIn)}
      </Link>
    );
  };

  return (
    <Typography isMarginless type={tablet.greaterThan ? "body" : "footnote"}>
      {t(l.common.HaveAcount)}
      &nbsp;
      {renderSignInLink()}
    </Typography>
  );
};

export { FooterSignIn };
