import { useTranslation } from "react-i18next";

import { Tooltip } from "@aviary/components/Tooltip";
import { l } from "@aviary/locales/i18n";

import type { AviaryAvatarSize } from "../Avatar";

import * as styles from "./PlatformBadge.styles";

interface Props {
  size?: AviaryAvatarSize;
}

const PlatformBadge = ({ size = "medium" }: Props) => {
  const { t } = useTranslation();

  const badgeStyles = [styles.badge, styles.badgeSize[size]];

  return (
    <Tooltip placement="bottom" tooltipContent={t(l.aviary.avatar.wellevateLabel)}>
      <div css={badgeStyles} aria-label={t(l.aviary.avatar.wellevateLabel)}>
        <span css={styles.fontSize[size]}>W</span>
      </div>
    </Tooltip>
  );
};

export { PlatformBadge };
