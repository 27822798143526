import { Trans, useTranslation } from "react-i18next";

import { Link, Spacer, Typography, useToast } from "@aviary";
import { TwoFactorAuthenticationMethods } from "@shared/types/graphqlGenerated.d";
import { gRecaptchaExecute } from "@shared/utils";
import { l } from "@unauthenticated/shared/locales/i18n";

import {
  useResendTwoFactorAuthPasscodeMutation,
  type ResendTwoFactorAuthPasscodeData,
} from "./ResendTwoFactorAuthPasscode.mutation";

import * as styles from "./ResendOtp.styles";

interface Props {
  token: string;
  otpMethod: TwoFactorAuthenticationMethods;
  email?: string;
  phoneLast4?: string;
}

const ResendOtp = ({ token, otpMethod, email, phoneLast4 }: Props) => {
  const { t } = useTranslation();
  const { makeToast } = useToast();

  const onResendPasscodeComplete = (data: ResendTwoFactorAuthPasscodeData) => {
    const hasErrors = data?.auth?.userResendTwoFactorAuthentication?.errors?.message;
    const isSent = data?.auth?.userResendTwoFactorAuthentication?.sent;
    const isEmailOtpMethod = otpMethod === TwoFactorAuthenticationMethods.Email;

    if (hasErrors || !isSent) {
      const errorMessage = isEmailOtpMethod
        ? t(l.signIn.emailNotSent)
        : t(l.signIn.textMessageNotSent);

      return makeToast("error", errorMessage);
    }

    if (isSent) {
      const successMessage = isEmailOtpMethod
        ? t(l.signIn.EmailHasBeenSent, { email })
        : t(l.signIn.textMessageHasBeenSent, { phoneLast4 });

      makeToast("success", successMessage);
    }
  };

  const [resendPasscode, { loading: loadingResendPasscode }] =
    useResendTwoFactorAuthPasscodeMutation({
      onCompleted: onResendPasscodeComplete,
      onError: ({ message }) => makeToast("error", message),
    });

  const onResendPasscode = () => {
    if (!loadingResendPasscode) {
      gRecaptchaExecute(captchaToken => {
        resendPasscode({
          variables: {
            input: {
              token,
              captchaToken,
            },
          },
        });
      });
    }
  };

  const renderResendText = () => {
    if (otpMethod === TwoFactorAuthenticationMethods.Sms) {
      return (
        <Typography type="footnote" isMarginless>
          <Trans i18nKey={l.signIn.didNotGetTextMessage}>
            Didn't get the text message?
            <Link onClick={onResendPasscode}>Resend</Link>.
          </Trans>
        </Typography>
      );
    }

    return (
      <Typography type="footnote" isMarginless>
        <Trans i18nKey={l.signIn.DidNotGetEmail}>
          Didn't get the email? Check your spam folder or
          <Link onClick={onResendPasscode}>resend</Link>.
        </Trans>
      </Typography>
    );
  };

  return (
    <div css={styles.wrapper}>
      {renderResendText()}
      <Spacer height="half" />
      <Typography type="footnote" css={styles.expirationMessage} isMarginless>
        {t(l.signIn.twoFactorAuthForm.codeWillExpire)}
      </Typography>
    </div>
  );
};

export { ResendOtp };
