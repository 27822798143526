import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { CarouselItem, Typography } from "@aviary";
import { AuthFooter } from "@shared/components";
import { useSharedGlobalConfig } from "@shared/hooks";
import { l } from "@unauthenticated/shared/locales/i18n";

import { FooterSignIn } from "../FooterSignIn";
import { SignUpCarousel } from "../SignUpCarousel";

interface Props {
  isWithinViewport: boolean;
  hideFooterSignIn?: boolean;
  onSignInRedirect?: () => void;
}

const SignUpCarouselOrFooterPreMemo = ({
  isWithinViewport,
  hideFooterSignIn,
  onSignInRedirect,
}: Props) => {
  const { t } = useTranslation();
  const { isEmerson } = useSharedGlobalConfig();

  const renderCarousel = () =>
    isEmerson ? (
      <SignUpCarousel data-testid="emerson-carousel">
        <CarouselItem>
          <Typography type="footnote">
            <Trans i18nKey={l.practitionerSignUp.Emerson.PractUSPOne} />
          </Typography>
        </CarouselItem>
        <CarouselItem>
          <Typography type="footnote">{t(l.practitionerSignUp.Emerson.PractUSPTwo)}</Typography>
        </CarouselItem>
        <CarouselItem>
          <Typography type="footnote">
            <Trans i18nKey={l.practitionerSignUp.Emerson.PractUSPThree} />
          </Typography>
        </CarouselItem>
      </SignUpCarousel>
    ) : (
      <SignUpCarousel data-testid="fullscript-carousel">
        <CarouselItem>
          <Typography type="footnote">{t(l.practitionerSignUp.PractUSPOne)}</Typography>
        </CarouselItem>
        <CarouselItem>
          <Typography type="footnote">{t(l.practitionerSignUp.PractUSPTwo)}</Typography>
        </CarouselItem>
        <CarouselItem>
          <Typography type="footnote">{t(l.practitionerSignUp.PractUSPThree)}</Typography>
        </CarouselItem>
        <CarouselItem>
          <Typography type="footnote">{t(l.practitionerSignUp.PractUSPFour)}</Typography>
        </CarouselItem>
        <CarouselItem>
          <Typography type="footnote">{t(l.practitionerSignUp.PractUSPFive)}</Typography>
        </CarouselItem>
      </SignUpCarousel>
    );

  const renderFooter = () => (
    <AuthFooter>
      {!hideFooterSignIn && <FooterSignIn onSignInRedirect={onSignInRedirect} />}
    </AuthFooter>
  );

  return isWithinViewport ? renderCarousel() : renderFooter();
};

const SignUpCarouselOrFooter = React.memo(SignUpCarouselOrFooterPreMemo);
export { SignUpCarouselOrFooter };
