import type { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { FloatingLabelInput, Typography, Button } from "@aviary";
import { BackButton } from "@aviary/layouts";
import { useBreakpoints } from "@shared/hooks";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./BackupCodeForm.styles";

interface Props {
  backupCode: string;
  errors: string[];
  onChange: (backupCode: string) => void;
  onSubmit: () => void;
  onResetErrors: () => void;
  onBackToSignIn?: () => void;
}

const BackupCodeForm = ({
  backupCode,
  errors,
  onChange,
  onSubmit,
  onResetErrors,
  onBackToSignIn,
}: Props) => {
  const { t } = useTranslation();
  const { phoneLarge } = useBreakpoints();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onResetErrors();
    onChange(e.target.value);
  };

  return (
    <form css={styles.wrapper} onSubmit={onSubmit}>
      <Typography type="h1" sizeOverride="h3">
        {t(l.signIn.backupCodeForm.title)}
      </Typography>
      <Typography>{t(l.signIn.backupCodeForm.subtitle)}</Typography>
      <div css={styles.backupCodeInputWrapper}>
        <FloatingLabelInput
          id="backupCode"
          value={backupCode}
          handleChange={handleChange}
          label={t(l.signIn.backupCode)}
          errors={errors}
          type="text"
          required
        />
      </div>
      <Button
        css={styles.nextButton}
        onClick={onSubmit}
        disabled={!backupCode}
        isFullWidth={phoneLarge.lessThan}
      >
        {t(l.common.Next)}
      </Button>
      <BackButton
        text={t(l.signIn.twoFactorAuthForm.backToSignIn)}
        onClick={onBackToSignIn}
        isUnderLined={false}
      />
    </form>
  );
};

export { BackupCodeForm };
