import type { SerializedStyles } from "@emotion/serialize";
import { useTranslation } from "react-i18next";

import { Typography } from "@aviary";
import { Button } from "@aviary/components";
import { l } from "@shared/locales/i18n";

import * as styles from "./MagicLinkButton.styles";
export interface MagicLinkButtonProps {
  storeId?: string;
  disabled?: boolean;
  isOrTextRendered?: boolean;
  buttonStyles?: SerializedStyles;
  orStyles?: SerializedStyles;
}

const MagicLinkButton = ({
  storeId,
  disabled,
  isOrTextRendered,
  buttonStyles,
  orStyles,
}: MagicLinkButtonProps) => {
  const { t } = useTranslation();
  const linkURL = storeId
    ? `/continue-without-password?store_id=${storeId}`
    : "/continue-without-password";

  return (
    <div css={styles.buttonWrapper}>
      <Button
        data-e2e="magic-link-flow"
        css={[styles.magicLinkButton, buttonStyles]}
        disabled={disabled}
        to={linkURL}
      >
        {t(l.magicLink.ContinueWithoutPassword)}
      </Button>
      {isOrTextRendered && (
        <Typography type="footnote" isMarginless css={[styles.orSection, orStyles]}>
          {t(l.magicLink.Or)}
        </Typography>
      )}
    </div>
  );
};

export { MagicLinkButton };
