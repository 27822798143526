import { css } from "@emotion/react";

export const textCenter = css`
  text-align: center;
`;

export const fullWidth = css`
  width: 100%;
`;

export const continueWithButton = css`
  margin-top: 1rem;
`;

export const accountLockedWrapper = css`
  max-width: 26rem;
  margin: auto;
  padding-bottom: 2rem;
`;
