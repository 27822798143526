import { css, type Theme } from "@emotion/react";

import { dimensions, helpers } from "@styles";

export const magicLinkButton = (theme: Theme) => css`
  width: 250px;
  padding: 0.5625rem 0.3125rem 0.5625rem 0.5625rem;
  background-color: ${theme.system.backgroundBase};
  font-weight: normal;
  border-radius: ${dimensions.borderRadius};
  border: 1px solid ${theme.system.borderBase};
  color: ${theme.system.textLabelEmphasis};
  font-family: "Roboto", sans-serif;
  text-align: center;
  &:hover {
    box-shadow: 0 1px 2px 0 ${helpers.hexToRgba(theme.text.body, 0.25)};
    border: 1px solid ${theme.system.borderHover};
    background-color: ${theme.system.backgroundBase};
    color: ${theme.system.textLabelEmphasis};
  }
`;

export const orSection = (theme: Theme) => css`
  margin: 1rem 0;
  color: ${theme.system.borderHover};
  overflow: hidden;
  text-transform: uppercase;

  &:before,
  &:after {
    background-color: ${theme.system.borderHover};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 25%;
  }

  &:before {
    right: 0.5rem;
    margin-left: -50%;
  }

  &:after {
    left: 0.5rem;
    margin-right: -50%;
  }
`;

export const buttonWrapper = css`
  text-align: center;
  width: 100%;
  align-content: center;
`;
