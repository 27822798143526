import { useEffect, useState } from "react";

let hydrating = true;

/**
 * Small utility hooks to get the status of hydration. This is useful in
 * order to only have components render purely client side for example.
 */
export const useHydrated = () => {
  const [hydrated, setHydrated] = useState(() => !hydrating);

  useEffect(() => {
    if (hydrating) {
      hydrating = false;
      setHydrated(true);
    }
  }, []);

  return hydrated;
};
