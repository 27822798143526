import type { SetStateAction } from "react";
import { useTranslation, Trans } from "react-i18next";

import type { FloatingLabelInputProps } from "@aviary";
import { Link } from "@aviary";
import { EmailInput } from "@shared/components";
import { isEmailDotValid } from "@shared/components/EmailInput";
import { useSharedGlobalConfig } from "@shared/hooks";
import { useGlobalConfig } from "@unauthenticated/shared/context";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { l } from "@unauthenticated/shared/locales/i18n";

interface Props extends Omit<FloatingLabelInputProps, "id" | "label" | "type"> {
  isLoading?: boolean;
  value: string;
  isDirty: boolean;
  setEmail: (value: SetStateAction<string>) => void;
  isAccountExists: boolean;
  setIsAccountExists: (value: SetStateAction<boolean>) => void;
  checkIfAccountExistsWithRecaptcha?: (email: string) => void;
  handleCheckIfAccountExists?: any;
  onSignInRedirect?: () => void;
}

const PractitionerSignUpEmailInput = ({
  setEmail,
  setIsAccountExists,
  isAccountExists,
  handleCheckIfAccountExists,
  checkIfAccountExistsWithRecaptcha,
  isDirty,
  onSignInRedirect,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { emailRegex } = useGlobalConfig();
  const { isEmerson } = useSharedGlobalConfig();

  const handleSuggestedEmailClick = (suggestedEmail: string) => {
    setEmail(suggestedEmail);
    checkIfAccountExistsWithRecaptcha?.(suggestedEmail);
    handleCheckIfAccountExists?.cancel();
  };

  const onChange = e => {
    rest.onChange?.(e);
    setIsAccountExists(false);
  };

  const renderSignInRedirectButton = () => {
    if (onSignInRedirect) {
      return <Link onClick={onSignInRedirect}>sign in to continue</Link>;
    } else {
      const signInUrl = isEmerson ? authRoutes.emerson.login : authRoutes.login;

      return <Link href={`${signInUrl}/?email=${rest.value}`}>sign in to continue</Link>;
    }
  };

  const getError = () => {
    if (!isEmailDotValid(rest.value)) {
      return t(l.common.EmailMustHaveDot);
    }
    if (!emailRegex.test(rest.value)) {
      return t(l.common.EmailInvalidFormat);
    }

    if (isAccountExists) {
      return (
        <Trans i18nKey={l.common.EmailAlreadyExists}>
          An account with this email exists, {""}
          {renderSignInRedirectButton()}
        </Trans>
      );
    }
  };

  return (
    <EmailInput
      setEmail={setEmail}
      isDirty={isDirty}
      error={getError()}
      {...rest}
      onChange={onChange}
      onSuggestedEmailClick={handleSuggestedEmailClick}
    />
  );
};

export { PractitionerSignUpEmailInput };
