import type { QueryHookOptions } from "@apollo/client";
import { useQuery, gql } from "@apollo/client";

import type { PractitionerTypeFragment } from "@shared/data/fragments";
import { PRACTITIONER_TYPE_FRAGMENT } from "@shared/data/fragments";

const GET_PRACTITIONER_TYPES = gql`
  query PractitionerTypes_Shared_Query {
    practitionerTypes {
      ...PractitionerTypeFragment
    }
  }
  ${PRACTITIONER_TYPE_FRAGMENT}
`;

interface PractitionerTypesData {
  practitionerTypes: PractitionerTypeFragment[];
}

const usePractitionerTypesQuery = (options?: QueryHookOptions<PractitionerTypesData>) =>
  useQuery<PractitionerTypesData>(GET_PRACTITIONER_TYPES, options);

export type { PractitionerTypesData };
export { usePractitionerTypesQuery, GET_PRACTITIONER_TYPES };
